import { useState } from "react";
import EditorText from "../../../QuillEditoText/EditorText";
import GeneralModalCrud from "../../../utils/GeneralModalCrud";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import ReactSelect from "react-select";
import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { StatusCode } from "../../../../enums";
import {
  IErrorNotification,
  INewJuntaEjecutivaModal,
  INewJuntasEjecutivaModalValues,
} from "../../../../services/interfaces/Configurations/NewJuntaModal";
import { _DEFAULT_NEWJUNTAEJECUTIVAMODAL_VALUES } from "../defaultJuntaValues";
import ValidateValues from "../utilityFunctions/ValidateJuntaEjecutivaValues";
import {
  CreateJuntaEjecutiva,
  GetCargosJuntaEjecutiva,
  GetJuntaEjecutivaById,
  UpdateJuntaEjecutiva,
} from "../../../../services/services/JuntaEjecutiva";
import { IJuntaEjecutivaRequest } from "../../../../api/Interfaces/configurations/JuntasEjecutiva";
import CustomReactSelect from "../../../utils/CustomReactSelect";

const NewJuntaEjecutivaModal: React.FC<INewJuntaEjecutivaModal> = ({
  titleOfModal,
  ReloadData,
  isEdit,
  openModal,
  aditionalData,
  HandleCloseAllModals,
  MessageNotification,
}) => {
  const [values, setValues] = React.useState<INewJuntasEjecutivaModalValues>(
    _DEFAULT_NEWJUNTAEJECUTIVAMODAL_VALUES._VALUES
  );
  const [errorNotification, setErrorNotification] = React.useState<
    IErrorNotification
  >(_DEFAULT_NEWJUNTAEJECUTIVAMODAL_VALUES._ERROR_NOTIFICATION);
  const [loadingActive, setLoadingActive] = React.useState(false);
  const [cargos, setCargos] = useState<{ value: number, label: string }[]>([]);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const HandlePreviewImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name === "foto" && files && files[0]) {
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
    }

    setValues((prev) => ({
      ...prev,
      [name]: files ? files[0] : event.target.value,
    }));
  };

  const HandleRemoveImage = () => {
    setPreviewImage(null);

    setValues((prev) => ({
      ...prev,
      foto: "",
    }));

    const fileInput = document.querySelector<HTMLInputElement>(
      'input[name="foto"]'
    );
    if (fileInput) {
      fileInput.value = "";
    }
  };

  React.useEffect(() => {
    isEdit && HandleGetJuntaEjecutivaById();
    LoadCargosEjecutivos();
  }, []);

  //#region GET / GETBYID / CREATE / UPDATE
  async function HandleGetJuntaEjecutivaById() {
    try {
      setLoadingActive(true);
      const { data, status } = await GetJuntaEjecutivaById(
        aditionalData?.id as number
      );
      if (status === StatusCode.Ok) {
        const JuntaEjecutiva: INewJuntasEjecutivaModalValues = {
          nombre: data.results.nombre,
          apellido: data.results.apellido,
          cargo: data.results.cargo,
          foto: data.results.foto,
        };
        setValues(JuntaEjecutiva);
      }
    } catch (err: any) {
      MessageNotification(err.response.data.errores, "error");
    } finally {
      setLoadingActive(false);
    }
  }
  async function LoadCargosEjecutivos() {
    try {
      setLoadingActive(true);
      const { data, status } = await GetCargosJuntaEjecutiva();

      if (status === StatusCode.Ok) {
        const CargosResults =
          data.results?.map((item) => {
            return { value: item.id, label: `${item.nombre}` };
          }) ?? [];
        setCargos(CargosResults);
      }
    } catch (err: any) {
      MessageNotification(
        err.response?.data?.errores || "Error al obtener los cargos.",
        "error"
      );
    } finally {
      setLoadingActive(false);
    }
  }

  async function HandleCreateNew() {
    try {
      setLoadingActive(true);
      if (HandleValidateCargos(values)) {
        const params: IJuntaEjecutivaRequest = {
          nombre: values.nombre as string,
          apellido: values.apellido as string,
          foto: values.foto as string,
          cargo: values.cargo,
        };
        const { data, status } = await CreateJuntaEjecutiva(params);
        if (status === StatusCode.Ok) {
          HandleCloseAllModals();
          MessageNotification(
            "Miemrbo de la junta ejecutiva creado correctamente.",
            "success"
          );
          ReloadData();
        }
      }
    } catch (err: any) {
      MessageNotification(err.message ?? err.response.data.errores, "error");
    } finally {
      setLoadingActive(false);
    }
  }
  async function HandleUpdateNew() {
    try {
      setLoadingActive(true);
      if (HandleValidateValues(values)) {
        const params: IJuntaEjecutivaRequest = {
          nombre: values.nombre as string,
          apellido: values.apellido as string,
          cargo: values.cargo,
          foto: values.foto as string,
        };
        const { data, status } = await UpdateJuntaEjecutiva(
          aditionalData?.id as number,
          params
        );
        if (status === StatusCode.Ok) {
          HandleCloseAllModals();
          MessageNotification(
            "Miembro de junta ejecutiva editado correctamente.",
            "success"
          );
          ReloadData();
        }
      }
    } catch (err: any) {
      MessageNotification(err.message ?? err.response.data.errores, "error");
    } finally {
      setLoadingActive(false);
    }
  }
  //#endregion

  //#region UTILS
  function HandleValidateValues(values) {
    const { validateObj, objError } = ValidateValues(values);
    if (!validateObj.validate) {
      setErrorNotification(objError);
      MessageNotification(validateObj.message, "error");
    }
    return validateObj.validate;
  }
  function HandleValidateCargos(values) {
    const { validateObj, objError } = ValidateValues(values);
    if (!validateObj.validate) {
      setErrorNotification(objError);
      MessageNotification(validateObj.message, "error");
    }
    return validateObj.validate;
  }
  function HandleChangeCargos(e: any) {
    setValues({
      ...values,
      cargo: {id: e.value, nombre: e.label}
    });
  }
  function HandleChangeInput(e: any) {
    setValues({
      ...values,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  }
  //#endregion

  return (
    <>
      {loadingActive ? (
        <Dimmer className="loader-fixed" active inverted>
          <Loader size="big">Cargando...</Loader>
        </Dimmer>
      ) : (
        <GeneralModalCrud
          titleOfModal={titleOfModal}
          openModal={openModal}
          HandleCloseModal={HandleCloseAllModals}
          HandleSubmit={isEdit ? HandleUpdateNew : HandleCreateNew}
        >
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 25,
            }}
          >
            <TextField
              value={values.nombre}
              label="Nombre"
              error={errorNotification.nombre}
              size="small"
              name="nombre"
              type="text"
              variant="outlined"
              onChange={HandleChangeInput}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              value={values.apellido}
              label="Apellido"
              error={errorNotification.nombre}
              size="small"
              name="apellido"
              type="text"
              variant="outlined"
              onChange={HandleChangeInput}
              InputLabelProps={{ shrink: true }}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <TextField
                label="Foto"
                error={errorNotification.foto}
                size="medium"
                name="foto"
                type="file"
                variant="outlined"
                onChange={HandleChangeInput}
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: "image/*" }}
              />

              {previewImage && (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100px",
                  }}
                >
                  <img
                    src={previewImage}
                    alt="Previsualización"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                  <button
                    onClick={HandleRemoveImage}
                    style={{
                      position: "absolute",
                      color: "black",
                      border: "none",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  >
                    ✖
                  </button>
                </div>
              )}
            </div>
          </section>
          <section>
            <CustomReactSelect
              placeholder="Cargo Ejecutivo"
              label="Cargo Ejecutivo"
              name="cargo"
              isLoading={false}
              options={cargos}
              value={cargos.find((x: any) => x?.value === values?.cargo?.id) ?? null}
              setValue={HandleChangeCargos}
              customStyles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              isClearable={true}
            />
          </section>
        </GeneralModalCrud>
      )}
    </>
  );
};

export default NewJuntaEjecutivaModal;
