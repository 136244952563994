import DateFnsUtils from "@date-io/date-fns";
import CardContent from "@material-ui/core/CardContent";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { es } from "date-fns/locale";
import moment from "moment";
import { useEffect, useState } from "react";
import { GetProfesionals } from "../../services/services/Users";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Button, Card, Dimmer, Grid, Icon, Loader } from "semantic-ui-react";
import api from "../../api";
import {
  statusTransaccionExitosa,
  StatusTipoTransaccion,
  auditoriaSocialWork,
  TypeRoleNum,
} from "../../enums";
import "./IntegrationsAudit.css";
import IntegrationsAudit from "./IntegrationsAudit";
import { TextField } from "@material-ui/core";

const SelectFilter = ({ label, name, menuItemList, control, filtersLoading }) => (
  <Controller
    className="align-select width-input-filter-dash filter-input"
    styles={{
      // Fixes the overlapping problem of the component
      menu: (provided) => ({ ...provided, zIndex: 9999 }),
    }}
    as={Select}
    Label={label}
    placeholder={label}
    noOptionsMessage={() => "No hay registros"}
    getOptionLabel={(option) => option.name}
    getOptionValue={(option) => option.id}
    isLoading={filtersLoading}
    isClearable={true}
    isSearchable={true}
    options={menuItemList}
    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: "#6a5ef3",
      },
    })}
    control={control}
    name={name}
    id={name}
  />
);

export default function Dashboard({ setDataToast }) {
  const [dateFilter, setDateFilter] = useState({
    FechaDesde: null,
    FechaHasta: null
  });
  const { control, getValues } = useForm({
    defaultValues: {
      Profesional: null,
      ObraSocial: null,
      TipoTransaccion: null,
      EstadoTransaccion: null,
    },
  });
  const [numAutorzation, setNumAutorization] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [loadingActive, setLoadingActive] = useState();
  const [loadingSelect, setLoadingSelect] = useState({ professional: true });
  const [professionalList, setProfessionals] = useState();
  const [socialWorkList, setSocialWorkList] = useState([]);
  const [TipoTransaccion, setTipoTransaccion] = useState([]);
  const [TransaccionExitosa, setTransaccionExitosa] = useState([]);
  const [page, setPage] = useState(1);
  const loadForPage = 20;
  const [quantityPages, setQuantityPages] = useState(0);

  //#region LoadFilters
  useEffect(() => {
    LoadDataToFilters();
  }, [])
  function LoadDataToFilters() {
    //cargo los datos para el select de Obras Sociales
    const listIntegrationSocialWork = auditoriaSocialWork.map((item) => {
      return {
        id: item.value,
        name: item.label,
      };
    });
    setSocialWorkList(listIntegrationSocialWork);

    //cargo los datos para el select de Estado Transaccion
    const TransaccionExitosaStates = statusTransaccionExitosa.map((item) => {
      return {
        id: item.value,
        name: item.label,
      };
    });
    setTransaccionExitosa(TransaccionExitosaStates);

    //cargo los datos para el select de Tipo Transacción
    const TipoTransaccionStates = StatusTipoTransaccion.map((item) => {
      return {
        id: item.value,
        name: item.label,
      };
    });
    setTipoTransaccion(TipoTransaccionStates);

    //cargo los datos para los profesionales
    GetProfesionalList();
  }
  async function GetProfesionalList() {
    try {
      const { status, data } = await GetProfesionals({ rolId: TypeRoleNum.Professional });
      if (status === 200) {
        const RSUserList = data?.results?.map((item) => ({
          id: item.id,
          name: `${item.apellido}, ${item.nombre}`,
        })) ?? [];
        setProfessionals(RSUserList);
      }
    } catch (err) {
      MessageNotification(err.message, "error");
    } finally {
      setLoadingSelect(prevState => ({ ...prevState, professional: false }));
    }
  }
  //#endregion

  const handleClickSearch = (nroPage = 1) => {
    setPage(nroPage);
    let values = getValues();
    let date = dateFilter;

    if (!date.FechaDesde || !date.FechaHasta) {
      MessageNotification("Debe ingresar fecha en ambos campos", "error");
      return;
    }
    if(numAutorzation !== null && Number(numAutorzation) < 0) {
      MessageNotification("El Número de Autorización no puede ser negativo", "error");
      return;
    }

    // const fechaUnMesAtras = moment().subtract(1, "months").startOf("day").format();
    // const fechaActual = moment().endOf("day").format();

    let filters = {
      fechaDesde: moment(date.FechaDesde).format("YYYY-MM-DD"),
      fechaHasta: moment(date.FechaHasta).format("YYYY-MM-DD"),
      profesionalId: values?.Profesional?.id ?? null,
      obraSocialId: values?.ObraSocial?.id ?? null,
      tipoTransaccionId: values?.TipoTransaccion?.id ?? null,
      transaccionExitosaId: values?.EstadoTransaccion?.id ?? null,
      numeroAutorizacion: numAutorzation ?? null,
      page: nroPage,
      quantity: loadForPage,
    };
    loadData(filters);
  }
  const loadData = (filters) => {
    setLoadingActive(true);
    Promise.all([
      api.getAuditory(filters)
    ])
      .then((resp) => {
        const listOfAuditory = resp[0].data.resultados;

        setQuantityPages(resp[0].data.cantidadPaginas);
        setDataTable(listOfAuditory);
      })
      .catch((err) => {
        MessageNotification(err.response.data.errores, "error");
      })
      .finally(() => {
        setLoadingActive(false);
      });
  }

  const handleChangePage = (event, value) => {
    handleClickSearch(value);
  }
  function MessageNotification(message, type) {
    setDataToast({
      active: true,
      message: message || "Acaba de ocurrir un error.",
      type: type,
    });
  }

  return (
    <>
      <Grid style={{ position: "relative" }}>
        <Card className="color-border-dash">
          <CardContent className="center-responsive-filters">
            <Grid.Row className="header-section">
              <div className="titleAndVideo">
                <span style={{ fontSize: 27 }}>Integraciones</span>
              </div>
            </Grid.Row>
            <Grid.Row className="row-filterList content-filter-list">
              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  className="size-date-picke-filter filter-input"
                  clearable
                  style={{ margin: 5 }}
                  value={dateFilter.FechaDesde}
                  placeholder="Fecha Desde"
                  onChange={(date) => setDateFilter({ ...dateFilter, FechaDesde: date })}
                  format="dd/MM/yyyy"
                  margin="normal"
                  inputVariant="outlined"
                />
                <KeyboardDatePicker
                  autoOk
                  className="size-date-picke-filter filter-input"
                  clearable
                  style={{ margin: 5 }}
                  value={dateFilter.FechaHasta}
                  placeholder="Fecha Hasta"
                  onChange={(date) => setDateFilter({ ...dateFilter, FechaHasta: date })}
                  format="dd/MM/yyyy"
                  margin="normal"
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>

              <SelectFilter
                label={"Profesional"}
                name={"Profesional"}
                control={control}
                menuItemList={professionalList}
                filtersLoading={loadingSelect.professional}
              />
              <SelectFilter
                label={"Obra Social"}
                name={"ObraSocial"}
                control={control}
                menuItemList={socialWorkList}
              />
              <SelectFilter
                label={"Tipo Transacción"}
                name={"TipoTransaccion"}
                control={control}
                menuItemList={TipoTransaccion}
              />
              <SelectFilter
                label={"Estado Transacción"}
                name={"EstadoTransaccion"}
                control={control}
                menuItemList={TransaccionExitosa}
              />

              <TextField
                className="align-select width-input-filter-dash filter-input"
                label="Número de Autorización"
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ min: 0 }}
                onChange={(e) => setNumAutorization(e.target.value)}
              />
            </Grid.Row>
            <Button
              className="btn-search-filter"
              primary
              onClick={() => handleClickSearch(1)}
              icon
              labelPosition="left"
            >
              <Icon name="search" />
              Buscar
            </Button>
          </CardContent>
        </Card>

        {loadingActive ? (
          <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Cargando..</Loader>
          </Dimmer>
        ) : (
          dataTable && (
            <IntegrationsAudit
              dataTable={dataTable}
              quantityPages={quantityPages}
              page={page}
              loadingActive={loadingActive}
              handleChangePage={handleChangePage}
            />
          )
        )}
      </Grid>
    </>
  );
}
