import { Tooltip } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReactSelect from 'react-select';
import TextField from '@material-ui/core/TextField';
import CachedIcon from '@material-ui/icons/Cached';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from "@material-ui/lab/Pagination";
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	Button,
	Card,
	Dimmer,
	Grid,
	Icon,
	Loader,
	Table
} from 'semantic-ui-react';
import api from '../../../../api';
import { Options, StatusCode } from '../../../../enums';

export default function Plan({ setDataToast }) {
	const { idOs } = useParams();
	const redirect = useHistory();
	//#region VARIABLES DE ESTADO
	const [loadingActive, setLoadingActive] = useState(false);
	const [assignPlanList, setAssignPlanList] = useState([{}]);
	const [plansList, setPlansList] = useState([]);
	const [openNew, setOpenNew] = useState(false);
	const [isCreate, setCreate] = useState(false);
	const [isEdit, setEdit] = useState(false);
	const [isDelete, setDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [quantityForPage, setQuantityForPage] = useState();
	const loadForPage = 20;
	const [page, setPage] = useState(1);
	const [reactivate, setReactivate] = useState({
		iconReactivate: false,
		modalReactivate: false
	});
	const [socialWork, setSocialWork] = useState({
		nombre: "",
		alias: "",
		esObraSocialColegio: false
	});
	const [objFilterData, setObjFilterData] = useState({ dadasDeBaja: false });
	const [openDelete, setOpenDelete] = useState({
		mostrar: false,
		id: null,
		nombre: null,
		alias: null,
		reporteAlias: null
	});
	const [newValue, setNewValue] = useState({
		id: null,
		nombre: null,
		idPlan: null,
		nombrePlan: null,
		alias: null,
		aliasReporte: null
	});
	const [editValues, setEditValues] = useState({
		id: null,
		alias: null,
		aliasReporte: null
	})
	const [selectedPlan, setSelectedPlan] = useState(null);
	//#endregion

	useEffect(() => {
		setLoadingActive(true);
		let optionsCrud = JSON.parse(localStorage.getItem('optionCruds'));
		optionsCrud.forEach((element) => {
			if (element == Options.isCreate) {
				setCreate(true);
			} else if (element == Options.isEdit) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			}
		});
		LoadData();
		GetSocialWorkById();
		getSocialWorksPlanList();
	}, []);

	useEffect(() => {
		// Si solo hay un plan, lo preseleccionamos
		if (plansList.length === 1) {
			setSelectedPlan(plansList[0]);
		}
	}, [plansList]);

	const LoadData = async (pageSelected = 1, assign = true) => {
		try {
			setLoadingActive(true);

			const bodyRequest = {
				obraSocialId: +idOs,
				planId: null, //TODO: REVISAR ESTO
				page: pageSelected,
				quantity: loadForPage,
				removed: objFilterData.dadasDeBaja
			}

			let { data } = await api.getSocialWorksPlansAccount(bodyRequest);

			let list = data.resultados.map(item => {
				return {
					id: item.id,
					nombre: item.plan.nombre,
					alias: item.planObraSocialAlias,
					reporteAlias: item.reporteAlias,
					obraSocial: {
						id: item.obraSocial.id,
						nombre: item.obraSocial.nombre
					},
					planObraSocial: {
						id: item.plan?.id,
						nombre: item.plan?.nombre
					}
				}
			});

			setQuantityForPage(data.cantidadPaginas);
			setAssignPlanList(list);
			setReactivate({ modalReactivate: false, iconReactivate: false });

			if (objFilterData.dadasDeBaja) {
				setReactivate({
					modalReactivate: false,
					iconReactivate: true
				});
			} else {
				setObjFilterData({ ...objFilterData, dadasDeBaja: false })
			}
		}
		catch (err) {
			HandleErrorOrSuccess('error', err.response.data.errores ?? "Ocurrió un error.");
		}
		finally {
			setLoadingActive(false);
		}
	}

	const OnClickAssign = async () => {
		setLoadingActive(true);
		try {
			let isValidated = checkInputs(selectedPlan?.value);

			if (isValidated) {
				let bodyRequest = {
					planObraSocialId: selectedPlan?.value,
					obraSocialId: +idOs,
					planObraSocialAlias: newValue.alias,
					reporteAlias: newValue.aliasReporte
				}
				let data = await api.CreateSocialWorksPlansAccount(bodyRequest);

				if (data.status === 200 && data.data != null) {
					HandleErrorOrSuccess('success', "Práctica Asociada correctamente.");

					setOpenNew(false);
					setNewValue({ id: null, nombre: null, alias: null, aliasReporte: null });
					setSelectedPlan(null);
					LoadData();
				}
			}
		} catch (err) {
			HandleErrorOrSuccess('error', err?.response?.data?.errores ?? "Ocurrió un error.");
		}
		finally {
			setLoadingActive(false);
		}
	}

	const OnClickEdit = async () => {
		setOpenEdit(false);
		try {
			setLoadingActive(true);
			let isValidated = checkInputs(editValues.id);

			if (isValidated) {
				let bodyParams = { planObraSocialAlias: editValues.alias, reporteAlias: editValues.aliasReporte };
				
				await api.UpdateSocialWorksPlansAccount(editValues.id, bodyParams);

				HandleErrorOrSuccess('success', "Plan editado correctamente.");
			}
		} catch (err) {
			HandleErrorOrSuccess('error', err?.response?.data?.errores ?? "Ocurrió un error.");
		}
		finally {
			setLoadingActive(false);
			setEditValues({
				id: null,
				alias: null,
				nombre: null,
				aliasReporte: null,
				idPlan: null,
				nombrePlan: null
			});
			LoadData();
		}
	}
	const OnClickDelete = async () => {
		setLoadingActive(true);
		HandleCloseDelete();
		try {
			let query = await api.DeleteSocialWorksPlansAccount(openDelete.id);
			if (query.status === 200) {
				HandleErrorOrSuccess('success', "Práctica dada de baja correctamente.");
			}
		} catch (err) {
			HandleErrorOrSuccess('error', err.response.data.errores ?? "Ocurrió un error.");
		}
		finally {
			setOpenDelete({
				mostrar: false,
				id: null,
				nombre: null,
				alias: null,
				reporteAlias: null
			});
			setLoadingActive(false);
			LoadData();
		}
	}
	//#region UTILIDADES
	const GetSocialWorkById = async () => {
		try {
			setLoadingActive(true);
			let { data } = await api.getSocialWorkById(+idOs);
			setSocialWork({
				nombre: data.nombre,
				alias: data.alias,
				esObraSocialColegio: data.esObraSocialColegio
			});
		} catch (err) {
			HandleErrorOrSuccess("error", err.response.data.errores ?? "Ocurrió un error.");
		}
		finally {
			LoadData();
		}
	}
	const checkInputs = (planId) => {
		if (planId === null || planId === undefined) {
			HandleErrorOrSuccess('error', `Los campos "Practica" y "Plan" no pueden estar vacíos.`);
			return false
		}
		return true;
	}
	const HandleErrorOrSuccess = (type, message) => {
		setDataToast({
			active: true,
			type: type,
			message: message
		});
	}
	const HandleOpenEditOrReactivate = (planId, alias, aliasReporte, esReactivar = false) => {
		setEditValues({
			id: planId,
			alias: alias,
			aliasReporte: aliasReporte
		});

		if (esReactivar) {
			setReactivate({ ...reactivate, modalReactivate: true });
		} else {
			setOpenEdit(true);
		}
	}

	async function getSocialWorksPlanList() {
		try {
			setLoadingActive(true);
			const plansData = await api.getSocialWorksPlan(+idOs);

			let planList = plansData.data.map(plan => {
				return { value: plan.id, label: plan["nombre"] }
			});

			setPlansList(planList);
		} catch (err) {
			HandleErrorOrSuccess('error', err?.response?.data?.errores ?? "Ocurrió un error.");
		} finally {
			setLoadingActive(false);
		}
	}

	async function HandleReactivatePractice() {
		try {
			setLoadingActive(true);
			const { status } = await api.ReactivarSocialWorksPlansAccount(editValues.id);
			if (status === StatusCode.Ok) {
				setReactivate({
					...reactivate,
					modalReactivate: false
				});
				HandleErrorOrSuccess("success", "Plan reactivada correctamente.");
				LoadData();
			}
		} catch (err) {
			HandleErrorOrSuccess('error', err?.response?.data?.errores ?? "Ocurrió un error.");
		} finally {
			setLoadingActive(false);
		}
	}

	const handleChange = (event, value) => {
		setPage(value);
		LoadData(value);
	};
	const HandleCloseModal = () => {
		setOpenEdit(false);
		setReactivate({
			...reactivate,
			modalReactivate: false
		});
	}
	const HandleCloseNew = () => {
		setOpenNew(false);
		if (plansList.length > 1) {
			setSelectedPlan(null);
		}
	}
	const HandleCloseDelete = () => {
		setOpenDelete({ ...openDelete, mostrar: false });
	}
	//#endregion

	return (<>
		<Grid>
			<Grid.Row>
				<Card className='color-border-dash'>
					<CardContent className='center-responsive-filters'>
						<Grid.Row className="header-section">
							<div className="titleAndVideo">
								<span style={{ fontSize: 27 }}>{socialWork?.alias ?? socialWork.nombre} - Asignar Planes</span>
							</div>
							<div className="contBtnHeaderSection">
								<Button
									className='btn-back-filter'
									onClick={() => redirect.push('/configuraciones/obras-sociales/asignar')}
								>
									Volver
								</Button>
								{isCreate &&
									<Button
										className='btn-new-filter'
										disabled={socialWork.esObraSocialColegio}
										onClick={() => setOpenNew(true)}
										icon
										labelPosition='left'
									>
										<Icon name='plus' />
										Asignar
									</Button>}
							</div>
						</Grid.Row>
						<Grid.Row className="content-filter-list">
							<FormControlLabel
								control={
									<Checkbox
										checked={objFilterData.dadasDeBajaChecked}
										onChange={(e) => setObjFilterData({ dadasDeBaja: e.target.checked })}
									/>
								}
								label='Dadas de baja'
							/>
						</Grid.Row>
						<Button
							className='btn-search-filter'
							primary
							icon
							labelPosition='left'
							onClick={() => LoadData()}
							type='submit'
						>
							<Icon name='search' />
							Buscar
						</Button>
					</CardContent>
				</Card>
			</Grid.Row>
			{loadingActive ? (
				<Dimmer className='loader-fixed' active inverted>
					<Loader size='big'>Cargando..</Loader>
				</Dimmer>
			) : (
				assignPlanList &&
				assignPlanList.length > 0 && (
					<>
						<Grid.Row>
							<TableContainer component={Paper} id='color-letter-table-tasks'>
								<Table aria-label='spanning table'>
									<TableHead colSpan={6} className='color-letter-inTable'>
										<TableRow>
											<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
												<b>Plan</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
												<b>Alias</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
												<b>Alias Reporte</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
												<b>Obra Social</b>
											</TableCell>
											<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
												<b>Opciones</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{assignPlanList.map(plan => (
											<TableRow key={plan.id}>
												<TableCell className='c-text-center' style={{ textAlign: "center" }}>
													{plan.nombre}
												</TableCell>
												<TableCell className='c-text-center' style={{ textAlign: "center" }}>
													{plan.alias}
												</TableCell>
												<TableCell className='c-text-center' style={{ textAlign: "center" }}>
													{plan.reporteAlias ?? "--"}
												</TableCell>
												<TableCell className='c-text-center' style={{ textAlign: "center" }}>
													{plan.obraSocial?.nombre}
												</TableCell>
												<TableCell className='c-text-center' style={{ textAlign: "center" }}>
													{reactivate.iconReactivate ?
														<>
															<IconButton
																className='btn-circle-green'
																onClick={() => HandleOpenEditOrReactivate(plan.id, plan.alias, plan.aliasReporte, true)}
															>
																<Tooltip placement='top' title="Reactivar">
																	<CachedIcon />
																</Tooltip>
															</IconButton>
														</>
														:
														<>
															{isEdit && (
																<IconButton
																	className='btn-circle-orange'
																	onClick={() => HandleOpenEditOrReactivate(plan.id, plan.alias, plan.reporteAlias, false)}
																>
																	<Tooltip placement='top' title="Editar">
																		<CreateIcon />
																	</Tooltip>
																</IconButton>
															)}
															{isDelete && socialWork.esObraSocialColegio === false && (
																<IconButton
																	className='btn-circle-red'
																	onClick={() => setOpenDelete({
																		id: plan.id,
																		nombre: plan.nombre,
																		alias: plan.alias,
																		reporteAlias: plan.reporteAlias,
																		mostrar: true
																	})}
																>
																	<Tooltip placement='top' title="Eliminar">
																		<DeleteIcon />
																	</Tooltip>
																</IconButton>
															)}
														</>}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid.Row>
						<Grid.Row centered>
							<Pagination
								color="primary"
								className="pagination"
								count={quantityForPage}
								page={page}
								onChange={handleChange}
							/>
						</Grid.Row>
					</>
				)
			)}
			{assignPlanList && assignPlanList.length === 0 && (
				<Grid.Row style={{ textAlign: '-webkit-center' }}>
					<TableContainer component={Paper} id='color-letter-table-tasks'>
						<TableCell>
							<h5>No se encontraron resultados</h5>
						</TableCell>
					</TableContainer>
				</Grid.Row>
			)}
		</Grid>

		<Dialog
			open={openNew}
			onClose={HandleCloseNew}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title'>Asignar Nuevo Plan</DialogTitle>
			<DialogContent className='cont-default-modal contModalAmount'>
				<div style={{ position: "relative" }}>
					<label htmlFor="my-select" className="customLabelSelect">Planes:</label>
					<ReactSelect
						placeholder="Planes..."
						options={plansList}
						onChange={(e) =>
							setSelectedPlan({
								value: e?.value || null,
								label: e?.label || "",
							})
						}
						styles={{
							control: (s) => ({ ...s, maxWidth: 300 })
						}}
						value={selectedPlan}
					/>
				</div>
				<TextField
					size='small'
					id='alias'
					label='Alias Plan'
					value={newValue.alias}
					onChange={(e) => {
						setNewValue({
							...newValue,
							alias: e.target.value === "" ? null : e.target.value
						});
					}}
				/>
				<TextField
					size='small'
					id='alias'
					label='Alias Reporte'
					style={{ position: "relative" }}
					value={newValue.aliasReporte}
					onChange={(e) => {
						setNewValue({
							...newValue,
							aliasReporte: e.target.value === "" ? null : e.target.value
						});
					}}
				/>
			</DialogContent>

			<DialogActions style={{ textAlign: 'center', justifyContent: 'center' }}>
				<Button onClick={HandleCloseNew}>
					Cancelar
				</Button>
				<Button onClick={OnClickAssign} color='green'>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>

		{openEdit && (
			<Dialog
				open={openEdit}
				onClose={HandleCloseModal}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Editar</DialogTitle>
				<DialogContent>
					<TextField
						style={{ margin: "10px 0" }}
						size='small'
						id='nombre'
						label='Alias Plan'
						value={editValues.alias}
						onChange={(e) => {
							setEditValues({
								...editValues,
								alias: e.target.value === "" ? null : e.target.value,
							});
						}}

					/>
					<TextField
						style={{ margin: "10px 0" }}
						size='small'
						id='nombre'
						label='Alias Reporte'
						value={editValues.aliasReporte}
						onChange={(e) => {
							setEditValues({
								...editValues,
								aliasReporte: e.target.value === "" ? null : e.target.value,
							});
						}}

					/>
				</DialogContent>
				<DialogActions
					style={{
						textAlign: 'center',
						justifyContent: 'center',
					}}
				>
					<Button onClick={() => HandleCloseModal()}>
						Cancelar
					</Button>

					<Button onClick={OnClickEdit} color='green'>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		)}

		{openDelete.mostrar &&
			<Dialog open={openDelete.mostrar} onClose={HandleCloseDelete}>
				<DialogTitle id='form-dialog-title'>Borrar Registro</DialogTitle>
				<DialogContent>
					<Grid.Row>
						<DialogContentText className='c-text-center'>
							¿Está seguro de eliminar el siguiente Plan: {openDelete?.alias ?? openDelete?.nombre}?
						</DialogContentText>
					</Grid.Row>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleCloseDelete()}>Cancelar</Button>
					<Button
						onClick={() => OnClickDelete()}
						color='red'
					>
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>
		}

		{reactivate.modalReactivate &&
			<Dialog open={reactivate.modalReactivate} onClose={HandleCloseModal}>
				<DialogTitle id='form-dialog-title'>Reactivar Registro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						¿Está seguro de reactivar la siguiente Práctica: {editValues.nombre}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleCloseModal()}>Cancelar</Button>
					<Button
						onClick={HandleReactivatePractice}
						color='green'
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		}
	</>)
}