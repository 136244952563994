import { IErrorNotification, INewJuntasEjecutivaModalValues } from '../../../../services/interfaces/Configurations/NewJuntaModal';
import { _DEFAULT_NEWJUNTAEJECUTIVAMODAL_VALUES } from '../defaultJuntaValues';

const ValidateValues = (values: INewJuntasEjecutivaModalValues) => {
    let validateObj = {
        validate: true,
        message: ""
    };
    let objError: IErrorNotification = _DEFAULT_NEWJUNTAEJECUTIVAMODAL_VALUES._ERROR_NOTIFICATION;
    const allValues = Object.entries(values);

    if (allValues.some(input => input[1] === null)){
        validateObj = {validate: false, message: "Todos los campos son obligatorios."};
        allValues.forEach(input => {
            if (input[1] === null) {
                objError = {
                    ...objError,
                    [input[0]]: true
                }
            }
        });
    }
    return {validateObj, objError}
}

export default ValidateValues;