export const _DEFAULT_NEWJUNTAEJECUTIVAMODAL_VALUES = {
    _VALUES: {
        nombre: null,
        apellido: null,
        cargo: { id: null, nombre: null },
        foto: null
    },
    _ERROR_NOTIFICATION:{
        nombre: false,
        text: false,
        foto: false,
        body: false
    },
    _DETAIL_NOTIFICATION_LIST:{
        show: false,
        data: null
    }
}

export const _DEFAULT_JUNTAJECUTIVA_VALUES = {
    _FILTER_OBJ: {
        nombre: null,
        apellido: null,
        page: 1,
        pageSize: 100,
        deleted: false
    },
    _MODALS: {
        new: {show: false, data: null},
        edit: {show: false, data: null},
        delete: {show: false, data: null},
    }
}