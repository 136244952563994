import { Typography } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SideBarItem from "../sideBar/SideBarItem";
import { TypeRole } from "../../enums";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={5}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const useStyles = makeStyles(()=>({
	sideBarIconStyles: {
		fontSize: "2em", 
		marginRight: 5,
		transition: 'margin-left 0.3s ease !important',
	},
	sideBarTextItemStyles: {
		fontSize: "1em", 
		color: "#363534",
		transition: 'opacity 0.3s ease !important',
	}
}));

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

export default function ManagmentSettings() {
  const classStyle = useStyles();
  const [typeRolUser, setTypeRolUser] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
	setTypeRolUser(JSON.parse(localStorage.getItem('TypeRole')));
  },[]);
  
  return (
		<>
			<SideBarItem urlToRedirect="#" titleTooltip="Gestión" dispatchAction={handleClick}>
				<>
					<WidgetsOutlinedIcon className={classStyle.sideBarIconStyles}/>
					<Typography className={classStyle.sideBarTextItemStyles}>Gestión</Typography>	
				</>
			</SideBarItem>

			<StyledMenu
				id='customized-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<StyledMenuItem
					onClick={() => {
						history.push('/descuentos/lista');
						setAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<SendIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText primary='Descuentos' />
				</StyledMenuItem>

				<StyledMenuItem
					onClick={() => {
						history.push('/cuotas/lista');
						setAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<SendIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText primary='Cuotas' />
				</StyledMenuItem>

				<StyledMenuItem
					onClick={() => {
						history.push('/acreditaciones/lista');
						setAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<SendIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText primary='Acreditaciones' />
				</StyledMenuItem>

				<StyledMenuItem
					onClick={() => {
						history.push('/facturas/lista');
						setAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<SendIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText primary='Facturas' />
				</StyledMenuItem>

				<StyledMenuItem
					onClick={() => {
						history.push('/recibos/lista');
						setAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<SendIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText primary='Recibos' />
				</StyledMenuItem>

				<StyledMenuItem
					onClick={() => {
						history.push('/configuraciones/regional');
						setAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<SendIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText primary='Configuración de Regional' />
				</StyledMenuItem>

				<StyledMenuItem
					onClick={() => {
						history.push('/junta-Ejecutiva/lista');
						setAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<SendIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText primary='Junta Ejecutiva' />
				</StyledMenuItem>

				{TypeRole.SuperAdmin === typeRolUser &&
				<>
				<StyledMenuItem
					onClick={() => {
						history.push('/configuraciones/retenciones');
						setAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<SendIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText primary='Retenciones de Ganancias' />
				</StyledMenuItem>
				<StyledMenuItem
					onClick={() => {
						history.push('/configuraciones/bancos');
						setAnchorEl(null);
					}}
				>
					<ListItemIcon>
						<SendIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText primary='Bancos' />
				</StyledMenuItem>
				</>
				}
			</StyledMenu>
		</>
	);
}
