import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IJuntaEjecutivaCargosResponse, IJuntaEjecutivaRequest, IJuntaEjecutivaResponse } from "../../api/Interfaces/configurations/JuntasEjecutiva";
import errorHandler from "../errorHandler";

const GetJuntaEjecutivaList = async (): Promise<IApiResponse<IJuntaEjecutivaResponse[]>> => {
    try{
        const response = await api.GetJuntaEjecutivaList();
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetCargosJuntaEjecutiva = async (): Promise<IApiResponse<IJuntaEjecutivaCargosResponse[]>> => {
    try{
        const response = await api.GetJuntaEjecutivaCargos();
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const CreateJuntaEjecutiva = async (params: IJuntaEjecutivaRequest): Promise<IApiResponse<number>> => {
    try{
        const response = await api.CreateNewJuntaEjecutiva(params);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const UpdateJuntaEjecutiva = async (miembroId: number, params: IJuntaEjecutivaRequest): Promise<IApiResponse<void>> => {
    try{
        const response = await api.UpdateJuntaEjecutiva(miembroId, params);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const GetJuntaEjecutivaById = async (miembroId: number): Promise<IApiResponse<IJuntaEjecutivaResponse>> => {
    try{
        const response = await api.GetJuntaEjecutivaById(miembroId);
        return {
            data: {results: response.data},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

const DeleteJuntaEjecutiva = async (miembroId: number): Promise<IApiResponse<void>> => {
    try{
        const response = await api.DeleteJuntaEjecutiva(miembroId);
        return {
            data: {results: undefined},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

export {GetJuntaEjecutivaList, CreateJuntaEjecutiva,  UpdateJuntaEjecutiva, GetJuntaEjecutivaById, DeleteJuntaEjecutiva, GetCargosJuntaEjecutiva}