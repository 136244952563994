import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Icon } from "semantic-ui-react";
import ButtonBox from '../../utils/ButtonBox';
import GeneralList from '../../utils/GeneralList';
import { StatusCode } from '../../../enums';
import GeneralModalDelete from '../../utils/GeneralModalDelete';
import { _DEFAULT_NEWJUNTAEJECUTIVAMODAL_VALUES, _DEFAULT_JUNTAJECUTIVA_VALUES } from './defaultJuntaValues';
import { DeleteJuntaEjecutiva, GetCargosJuntaEjecutiva, GetJuntaEjecutivaList } from '../../../services/services/JuntaEjecutiva';
import { IJuntaEjecutivaResponse } from '../../../api/Interfaces/configurations/JuntasEjecutiva';
import { IJuntasEjecutivasFilterObj, IJuntasEjecutivasModals } from '../../../services/interfaces/Configurations/IJuntasEjecutivas';
import NewJuntaEjecutivaModal from './modals/NewJuntaEjecutivaModal';

const JuntasEjecutivas: React.FC<any> = ({setDataToast}) => {
    const [loadingActive, setLoadingActive] = React.useState(false);
    const [dataToRender, setDataToRender] = React.useState<IJuntaEjecutivaResponse[]>([]);
    const [page, setPage] = React.useState(1);
    const [loadForPage, setLoadForPage] = React.useState(1);
    const [filterObj, setFilterObj] = React.useState<IJuntasEjecutivasFilterObj>(_DEFAULT_JUNTAJECUTIVA_VALUES._FILTER_OBJ);
    const [modals, setModals] = React.useState<IJuntasEjecutivasModals>(_DEFAULT_JUNTAJECUTIVA_VALUES._MODALS);
    const [cargos, setCargos] = useState<any>();

    React.useEffect(()=>{
            LoadDataForTable();
            LoadCargosEjecutivos();
        },[]);
    
        const ButtonNew = ()=>(
            <Button
            className='btn-new-filter'
            onClick={(e)=>HandleOpenModal(e)}
            icon
            name="new"
            labelPosition='left'
            >
            <Icon name='plus' />
            Nuevo
            </Button>
        );
        const FilterComponents = [
            (key) => (
                <TextField
                key={key}
                value={filterObj.nombre || filterObj.apellido || ""} 
                label="Nombre y Apellido"
                size="small"
                type="text"
                variant="outlined"
                onChange={(e) => {
                  const value = e.target.value.trim();
                  setFilterObj((prev) => ({
                    ...prev,
                    nombre: value === "" ? null : value.toLowerCase(),
                  }));
                }}
                InputLabelProps={{ shrink: true }}
                style={{ width: "250px", height: "45px" }}
              />
            ),
          ];
          

    async function LoadCargosEjecutivos() {
        try {
            setLoadingActive(true);
            const { data, status } = await GetCargosJuntaEjecutiva();
            if (status === StatusCode.Ok) {
                const CargosResults = data?.results?.map(item=>{return {value: item.id, label: `${item.nombre}`}}) ?? [];
                setCargos(CargosResults);
            }
        } catch (err: any) {
            MessageNotification(err.response?.data?.errores || "Error al obtener los cargos.", "error");
        } finally {
            setLoadingActive(false);
        }
    } 
            
    async function LoadDataForTable(){
        try{
            setLoadingActive(true);
            const {data, status} = await GetJuntaEjecutivaList();
            if (status === StatusCode.Ok){
                setDataToRender(data.results);
                setLoadForPage(data.quantity ?? 1);
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }
    
    async function DeleteJuntaEjecutivaSelected(){
        try{
            setLoadingActive(true);
            const {data, status} = await DeleteJuntaEjecutiva(modals.delete.data?.id as number);
            if (status === StatusCode.Ok){
                HandleCloseAllModals();
                MessageNotification("Miembro de junta ejecutiva de eliminado correctamente.", "success");
                LoadDataForTable();
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores, "error");
        }finally{
            setLoadingActive(false);
        }
    }

    function HandleCloseAllModals(){
        setModals(_DEFAULT_JUNTAJECUTIVA_VALUES._MODALS);
    }
    function HandleChangePage(pageSelected: number){
        setPage(pageSelected);
        LoadDataForTable();
    }
    function HandleOpenModal(event, data: IJuntaEjecutivaResponse | null = null){
        setModals({
            ...modals,
            [event.currentTarget.name]: {show: true, data: data}
        })
    }
    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message,
            type
        });
    }

    return (
        <>
        <GeneralList
        titleOfSection='Junta Ejecutiva'
        isLoaderActive={loadingActive}
        dataToRender={dataToRender}
        initialPage={page}
        numPagesToRender={loadForPage}
        TestFilterComponents={FilterComponents}
        HandleSearch={LoadDataForTable}
        ButtonNew={ButtonNew}
        onChangePage={HandleChangePage} 
        >
        <Table aria-label='spanning table'>
            <TableHead>
                <TableRow>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Nombre</b>
                    </TableCell>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Cargo</b>
                    </TableCell>
                    <TableCell className='letter-title-table c-text-center'>
                        <b>Opciones</b>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {dataToRender.map((item: any) => (
                    <TableRow key={item.id}>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                        {item.nombre} {item.apellido}
                        </TableCell>
                        <TableCell className='letter-title-table c-text-center p-cell'>
                            {item.cargo.nombre}
                        </TableCell>
                        <TableCell className='p-cell'>
                        <Button.Group>
                                <ButtonBox buttonToShow='edit' name="edit" HandleOnClik={(e)=>HandleOpenModal(e, item)}/>
                                <ButtonBox buttonToShow='delete' name="delete" HandleOnClik={(e)=>HandleOpenModal(e, item)}/>
                            </Button.Group>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </GeneralList>

        {modals.new.show &&
        <NewJuntaEjecutivaModal
        titleOfModal='Nuevo'
        aditionalData={modals.new.data}
        isEdit={false}
        openModal={modals.new.show}
        HandleCloseAllModals={HandleCloseAllModals}
        MessageNotification={MessageNotification}
        ReloadData={LoadDataForTable}
        />}

        {modals.edit.show &&
        <NewJuntaEjecutivaModal
        titleOfModal='Editar'
        aditionalData={modals.edit.data}
        isEdit={true}
        openModal={modals.edit.show}
        HandleCloseAllModals={HandleCloseAllModals}
        MessageNotification={MessageNotification}
        ReloadData={LoadDataForTable}
        />}
        
        {modals.delete.show &&
        <GeneralModalDelete 
        contentText={`¿Está seguro de eliminar a ${modals.delete.data?.nombre} ${modals.delete.data?.apellido}?`}
        openModal={modals.delete.show}
        HandleClose={HandleCloseAllModals}
        HandleSubmit={DeleteJuntaEjecutivaSelected}
        />}
        </>
    );
}

export default JuntasEjecutivas;
